import * as React from 'react'
import Layout from '@components/layout'
import { Link } from 'gatsby'
import TheEnd from '@components/theend'
import { useTranslation } from 'react-i18next'
import Box from '@common/box'
import { StaticImage } from 'gatsby-plugin-image'

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <Layout titleOverride="Datahanke" descriptionOverride="AI & ML liiketoimintajärjestelmissä">
      <Box>
        <header>
          <div className="flex justify-center">
            <StaticImage src="../images/lottery.png" alt="Taitopilven palvelut ja ratkaisut: digitaaliset palvelut, tilauksen- ja toimituksen hallinta, data ja tekoäly" layout="constrained" width={666}/>
          </div>
        </header>
        <section>
          <p>Taitopilvi osallistui vuosien 2022-2023 aikana EU:n rahoittamaan Datahankkeeseen. Hankkeen pääasiallisena tavoitteena oli luoda Taitopilven palvelutarjontaan uusi liiketoimintasegmentti data-analytiikan ja koneoppimisen yhdistämiseen pilvipalveluissa. Tämä tuki strategisia kasvutavoitteitamme luoden uusia työpaikkoja ja pohjaa tulevaisuuden kehittämistoimille.</p>
          <div className="flex justify-center p-5">
            <StaticImage src="../images/eu.png" alt="Taitopilven palvelut ja ratkaisut: digitaaliset palvelut, tilauksen- ja toimituksen hallinta, data ja tekoäly" layout="constrained" width={300}/>
          </div>
        </section>
      </Box>
      <TheEnd />
    </Layout>
  )
}
